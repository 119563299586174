<!--The content below is only a placeholder and can be replaced.-->

<div class="columns is-centered bottomNone TCBlue ">
  <div class="column is-narrow bottomNone">
    <div class="level TCBlue">
      <div class="level-left">
        <div class="level-item TCLogo ">

          <!-- <img src="assets/TCLogo_1024x329.png" width="400"   alt="TrueCordis Logo"> -->
          <!-- <a href="http://www.truecordis.com"> -->
          <a href="https://truecordis.com/">
            <img src="assets/TCLogo_1024x329_new.png" width="350px" alt="TrueCordis Logo">
            <!-- <img src="assets/TCLogo_1024x329.png" width="350px"  alt="TrueCordis Logo"> -->

          </a>
        </div>
      </div>

      <div class="level-right">
        <div class="level-item">
          <a class=" navlink" href="https://truecordis.com/services"> Services</a>
        </div>
        <div class="level-item">
          <a class=" navlink" href="https://truecordis.com/about/"> About</a>
        </div>
        <div class="level-item">
          <a class=" navlink" href="https://truecordis.com/education-center/"> Education Center</a>
        </div>
        <div class="level-item">
          <a class=" navlink" href="https://truecordis.com/contact-us/"> Contact</a>
        </div>

        <div class="level-item"></div>
        <div class="level-item">


          <a class="button LoginBTN" href="http://client.truecordis.com/login/index.html"> <span
              style="margin-right:5px"> Log In</span> <i class="fa-solid fa-right-to-bracket"></i></a>
        </div>
      </div>
    </div>
    <div class="level is-hidden" style="background-color:#24384B;margin-bottom:0;">
      <div class="level-left">
        <div class="level-item" style="padding-left:auto">

          <!-- <img src="assets/TCLogo_1024x329.png" width="400"   alt="TrueCordis Logo"> -->
          <!-- <a href="http://www.truecordis.com"> -->
          <a href="https://truecordis.com/">
            <img src="assets/TCLogo_1024x329_new.png" width="400" alt="TrueCordis Logo">
            <!-- <img src="assets/TCLogo_1024x329.png" width="400"  alt="TrueCordis Logo"> -->

          </a>
        </div>
      </div>
      <div class="">
        <a class=" navlink" href="https://truecordis.com/services"> Services</a>
      </div>
      <div class="">
        <a class=" navlink" href="https://truecordis.com/about/"> About</a>
      </div>
      <div class="">
        <a class=" navlink" href="https://truecordis.com/education-center/"> Education Center</a>
      </div>
      <div class="">
        <a class=" navlink" style="" href="https://truecordis.com/contact-us/"> Contact</a>
      </div>
      <div class="level-right">
        <div class="level-item" style=" margin-right:50px">

          <a class="button" style="color:white;background-color:#3E465E;margin-left:40px;margin-bottom:20px;top:10px"
            href="http://client.truecordis.com/login/index.html"> Log In</a>
        </div>
      </div>
    </div>
  </div>
</div>


<!--   
<div class="level" style="background-color:#24384B;margin-bottom:0;">
  <div class="level-left">
    <div class="level-item" style="margin-left:50px" >
<a href="http://www.truecordis.com">
  <img src="assets/TCLogo_1024x329.png" width="400"  alt="TrueCordis Logo">

</a>
    </div>
  </div>
  <div class="level-right">
    <div class="level-item" style="margin-right:50px">

      <a class="button" 
      style="color:white;background-color:#3E465E;margin-left:40px;margin-bottom:10px"
      href="http://client.truecordis.com/login/index.html"> Log In</a>
    </div>
  </div>
</div> -->





<span class="TCLibraryBG">

</span>



<div class=" columns is-centered ">
  <!-- <div class="column is-1"></div> -->
  <!-- <div class="column is-1"></div> -->
  <div class="column is-narrow">

    <!-- <div ><button class="button">SIGN UP</button></div> -->

    <div class="box Info splashBox">
      <div>
        <h1 class="is-pulled-right signup " style="color:#C4B06D">Sign Up</h1>

      </div>
      <br>

      <div class="huge has-text-right">
        <p>WELCOME TO <br> <span class="" style="color: #cc2128;">TRUECORDIS </span> <br> SIGNUP FORM.</p>

      </div>
      <br>

      <br>
      <div class="has-text-right textInfo">
        <p>There are no sign up fees, membership fees, or monthly minimum charges.<br><span class="bg_TCred"><strong
              style="color: #cc2128;">You simply pay for what you order.</strong></span></p>

      </div>

      <br>
      <div *ngIf="!showForm">
        <a class="button is-large is-pulled-right getStarted" (click)="toggleForm(); runSignIn()">GET STARTED HERE</a>
        <!-- <a class="button is-large is-pulled-right getStarted" style="" href="https://signup.truecordis.com/signup/form.html" >GET STARTED HERE</a> -->
      </div>
    </div>
    <div class="box Info" *ngIf="showForm">
      <div class="box formBox">

        <form class="bm-0   mb-0" [class.is-hidden]="hideForm" [formGroup]="form">

          <!-- <button class="is-hidden button is-fullwidth is-info" (click)="getReport()" [disabled]="!reportIsReady">
            Run Report
          </button> -->

          <!-- <div class="columns">
            <div class="column"> -->
              <!--  *ngIf="!showMessage" -->
          <div class="field">
            <!-- {{signupInfoText}} -->
            <span *ngIf="!showMessage">
              Please fill out the following and press "Request Signup". 
            </span>

            After our team reviews your request, you will soon
            receive an email at the email address you just entered.
            <br />
            Please click on the link in the email you receive to confirm your email address and continue to fill out
            additional information. We will be contacting you soon.
            <br />
            <span >
              Thank you.
            </span>
            <br>
           
          </div>
          <!-- <div class="field" *ngIf="showMessage" >

          </div> -->

          <div class="field  is-grouped is-multiline" style="margin-top: 0px;">



            <div class="control">
              <label class="label" for="cFirstName">
                <span class="required">*&nbsp;</span>First Name</label>
              <div class="control">
                <div class="field has-addons">
                  <p class="control">
                    <input class="input name" type="text" (input)="formChanged()"
                      [class.is-danger]="form.controls.cFirstName.invalid && !form.controls.cFirstName.pristine "
                      pattern="^[a-zA-Z -]+$" formControlName="cFirstName" required />
                  </p>
                  <p class="control">
                    <button [disabled]="form.value.cFirstName === '' || form.value.cFirstName === null
                    || form.controls.cFirstName.status === 'DISABLED' "
                      (click)="clear('cFirstName')" class="button is-pulled-right">
                      <i class="fas fa-times"></i>
                    </button>
                  </p>
                </div>
              </div>
            </div>




            <div class="control">
              <label class="label" for="cLastName">
                <span class="required">*&nbsp;</span>Last Name</label>
              <div class="control">
                <div class="field has-addons">
                  <p class="control">
                    <input class="input name" type="text" (input)="formChanged()"
                      [class.is-danger]="form.controls.cLastName.invalid && !form.controls.cLastName.pristine "
                      pattern="^[a-zA-Z- ]+$" formControlName="cLastName" required />
                  </p>
                  <p class="control">
                    <button [disabled]="form.value.cLastName === '' || form.value.cLastName === null
                     || form.controls.cLastName.status === 'DISABLED' "
                      (click)="clear('cLastName')" class="button is-pulled-right">
                      <i class="fas fa-times"></i>
                    </button>
                  </p>
                </div>
              </div>
            </div>











          </div>
          <div class="field  is-grouped is-multiline">
            <!-- <label class="label">Find </label> -->

            <!-- <div class="control">
                              <label class="label" for="County"></label>
                              <input name="County" class="input " size="" type="text" placeholder="" formControlName="cCounty" /> 
                              
                        
                            </div>-->




            <!-- 
              
                    <div class="control">
                      <label class="label">Request Key</label>
                      <div class="control">
                        <div class="field has-addons">
                          <p class="control">
                            <input class="input" type="text" (input)="formChanged()"
                              [class.is-danger]="form.controls.iRequestKey.invalid && !form.controls.iRequestKey.pristine "
                              pattern="^[0-9]+$" formControlName="iRequestKey" />
                          </p>
                          <p class="control">
                            <button [disabled]="form.value.iRequestKey === '' || form.value.iRequestKey === null"
                              (click)="clear('iRequestKey')" class="button is-pulled-right">
                              <i class="fas fa-times"></i>
                            </button>
                          </p>
                        </div>
                      </div>
                    </div> -->








            <div class="control">
              <label class="label" for="cBusName">
                <span class="required">*&nbsp;</span>Company Name</label>
              <div class="control">
                <div class="field has-addons">
                  <p class="control">
                    <input class="input name long" type="text" (input)="formChanged()"
                      [class.is-danger]="form.controls.cBusName.invalid && !form.controls.cBusName.pristine "
                      pattern="^[a-zA-Z0-9 ,.-]+$" formControlName="cBusName" required />
                  </p>
                  <p class="control">
                    <button [disabled]="form.value.cBusName === '' || form.value.cBusName === null
                    || form.controls.cBusName.status === 'DISABLED' "
                      (click)="clear('cBusName')" class="button is-pulled-right">
                      <i class="fas fa-times"></i>
                    </button>
                  </p>
                </div>
              </div>
            </div>


            <div class="control">
              <label class="label" for="cTitle">Title</label>
              <!-- <span class="required">*</span>  -->

              <div class="control">
                <div class="field has-addons">
                  <p class="control">
                    <input class="input name long" type="text" (input)="formChanged()"
                      [class.is-danger]="form.controls.cTitle.invalid && !form.controls.cTitle.pristine "
                      pattern="^[a-zA-Z, ]+$" formControlName="cTitle" />
                  </p>
                  <p class="control">
                    <button [disabled]="form.value.cTitle === '' || form.value.cTitle === null
                    || form.controls.cTitle.status === 'DISABLED' "
                      (click)="clear('cTitle')" class="button is-pulled-right">
                      <i class="fas fa-times"></i>
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <!-- <div class="control">
                      <label class="label" for="cAddresscAddress">
                          <span class="required">*</span>Address </label>
                      <div class="control">
                        <div class="field has-addons">
                          <p class="control">
                            <input class="input name" type="text" (input)="formChanged()"
                              [class.is-danger]="form.controls.cAddress.invalid && !form.controls.cAddress.pristine "
                              pattern="^[a-zA-Z,0-9 ]+$" formControlName="cAddress"
                              required />
                          </p>
                          <p class="control">
                            <button [disabled]="form.value.cAddress === '' || form.value.cAddress === null"
                              (click)="clear('cAddress')" class="button is-pulled-right">
                              <i class="fas fa-times"></i>
                            </button>
                          </p>
                        </div>
                      </div>
                    </div> -->











          </div>




          <div class="field  is-grouped is-multiline" style="margin-top: 0px;">


            <div class="control">
              <label class="label" for="cEmail">
                <span class="required">*&nbsp;</span>Email</label>
              <div class="control">
                <div class="field has-addons">
                  <p class="control">
                    <input class="input name" type="email" (input)="formChanged('cEmail')"
                      [class.is-danger]="form.controls.cEmail.invalid && !form.controls.cEmail.pristine && Changed.cEmail"
                      formControlName="cEmail" pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$" required />
                  </p>
                  <!--  pattern="^[a-zA-Z,0-9 ]+$"  -->
                  <p class="control">
                    <button [disabled]="form.value.cEmail === '' || form.value.cEmail === null
                    || form.controls.cEmail.status === 'DISABLED' "
                      (click)="clear('cEmail')" class="button is-pulled-right">
                      <i class="fas fa-times"></i>
                    </button>
                  </p>
                </div>
              </div>
            </div>


            
            <div class="control">
              <label class="label" for="cEmailVerify">
                <span class="required">*&nbsp;</span>Confirm Email</label>
              <div class="control">
                <div class="field has-addons">
                  <p class="control">
                    <input class="input name" type="email" (input)="formChanged('cEmailVerify')"
                      [class.is-danger]="form.controls.cEmailVerify.invalid && !form.controls.cEmailVerify.pristine && Changed.cEmailVerify
                         "
                      formControlName="cEmailVerify"  required />
                  </p>
                  <!-- 
                    pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
                    pattern="^[a-zA-Z,0-9 ]+$"  -->
                  <p class="control">
                    <button [disabled]="form.value.cEmailVerify === '' || form.value.cEmailVerify === null
                    || form.controls.cEmailVerify.status === 'DISABLED' "
                      (click)="clear('cEmailVerify')" class="button is-pulled-right">
                      <i class="fas fa-times"></i>
                    </button>
                  </p>
                </div>
              </div>
            </div>

          </div>



          <div class="field  is-grouped is-multiline" style="margin-top: 0px;">

 


            <div class="control">
              <label class="label" for="cPhone">
                <span class="required">*&nbsp;</span>Phone</label>
              <!-- <span class="required">*</span> -->

              <div class="control">
                <div class="field has-addons">
                  <p class="control">
                    <input id="tel" class="input name" type="text" (blur)="formChanged('cPhone')"
                      [class.is-danger]="form.controls.cPhone.invalid && !form.controls.cPhone.pristine && Changed.cPhone"
                      phoneMask [preValue]="form.value.cPhone === null ? form.value.cPhone : '' "  [phoneControl]="form.controls['cPhone']"
                      pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$"
                      maxlength="14" 
                      formControlName="cPhone" />
                      
                  </p>
                  <p class="control">
                    <button [disabled]="form.value.cPhone === '' || form.value.cPhone === null
                    || form.controls.cPhone.status === 'DISABLED' "
                      (click)="clear('cPhone')" class="button is-pulled-right">
                      <i class="fas fa-times"></i>
                    </button>
                  </p>
                </div>
              </div>
            </div>
            


                        
            <div class="control">
              <label class="label">
                  <span class="required">*</span>Primary Business Specialty</label>
              <div class="select " [class.is-danger]="form.controls.cSpecialty.invalid && 
                        !form.controls.cSpecialty.pristine    ">
                <select (input)="formChanged('cSpecialty'); " #test formControlName="cSpecialty"
                required>
                  <option value=null disabled hidden > [ ? ] </option>
                  <option value="Banking/Finance "> Banking/Finance </option>
                  <option value="Church"> Church</option>
                  <option value="Other"> Other</option>
                  <option value="Pre-Employment Screening"> Pre-Employment Screening</option>
                  <option value="Private Investigations"> Private Investigations</option>
                  <option value="School"> School</option>
                  <option value="Security Firm"> Security Firm</option>
                  <option value="Tenant Screening"> Tenant Screening</option>
 
                </select>
              </div>
            </div>




          </div>


          <div class="field  is-grouped is-multiline" style="margin-top: 0px;">



            <div class="control">
              <label class="label" for="cClientWebsite">Client Website</label>
              <!-- <span class="required">*</span>  -->

              <div class="control">
                <div class="field has-addons">
                  <p class="control">
                    <input class="input name fullwidthInput " type="text" (input)="formChanged()"
                      [class.is-danger]="form.controls.cClientWebsite.invalid && !form.controls.cClientWebsite.pristine "
                      pattern="^[a-zA-Z0-9., ]+$" formControlName="cClientWebsite" />
                  </p>
                  <p class="control">
                    <button [disabled]="form.value.cClientWebsite === '' || form.value.cClientWebsite === null
                    || form.controls.cClientWebsite.status === 'DISABLED' "
                      (click)="clear('cClientWebsite')" class="button is-pulled-right">
                      <i class="fas fa-times"></i>
                    </button>
                  </p>
                </div>
              </div>
            </div>

            

          <div class="field" style="margin-top: 0px;">
            <p class="message is-danger"   *ngIf="errorMessage" >
              Emails Do Not Match
            </p> 
          </div>
          </div>


          <div class="field  is-grouped is-multiline" style="margin-top: 0px; margin-bottom:0px" *ngIf="!showMessage" >
            <form [formGroup]="aFormGroup">
              <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (reset)="handleReset()" (expire)="handleExpire()"
                (load)="handleLoad()" (success)="handleSuccess($event)" [useGlobalDomain]="false"
                formControlName="recaptcha">
              </ngx-recaptcha2>
            </form>
            <button [disabled]="  !form.valid || !captchaSuccess" style="height:75px;position:relative;top:0px;font-weight:bold"
              class="button is-block is-theme-light is-info signupButton" (click)="onSubmit()">
              Request 
              Signup
            </button>
          </div>

          <div class="field" style="margin-top: 0px;">
            <p class="message"    *ngIf="showMessage" >
              {{message}}
            </p> 
          </div>

        </form>
      </div>
    </div>


    <img *ngIf="!showForm" class=" signupImage" src="assets/SignupGuy.jpg" alt="">



    <!-- <div class="column underlap ">
      
      <img class="shadow" src="assets/SignupGuy.jpg" alt="">
      
    </div> -->
  </div>
  <!-- <div class="column  underlay ">

    </div> -->
  <!-- <div class="column is-1"></div> -->
</div>



<footer>


  <div class="level is-centered footStyle footer " style="">
    <div class="level-item">

      <div class="level-item">
        <a class=" navlink navlink-footer" href="https://truecordis.com/services"> Services</a>
      </div>
      <div class="level-item">
        <a class=" navlink navlink-footer" href="https://truecordis.com/about/"> About</a>
      </div>
      <div class="level-item">
        <a class=" navlink navlink-footer" href="https://truecordis.com/education-center/"> Education Center</a>
      </div>
      <div class="level-item">
        <a class=" navlink navlink-footer contact" href="https://truecordis.com/contact-us/"> Contact</a>
      </div>
    </div>


    <div class="level-item">
      <div class="level-item">
        <div class="credits">
          <!-- Website By: Marketing Strategy Coaches & Rojo 032 | Developer: Matthew Breitling -->
          Website By: Marketing Strategy Coaches & Rojo 032
          <!-- Designed By: Marketing Strategy Coaches & Rojo 032 | Developed By: Matthew Breitling -->

        </div>
      </div>
    </div>

  </div>
</footer>