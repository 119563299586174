import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { FormsModule } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';


import { AppComponent } from './app.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { DataService } from './services/data.service';
import { HttpClientModule } from '@angular/common/http';

import { PhoneMaskDirective } from './phone-mask.directive'; 

@NgModule({
  declarations: [
    AppComponent,
    SignUpComponent,
    PhoneMaskDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    HttpClientModule],
    
  providers: [DataService],
  bootstrap: [AppComponent]
})
export class AppModule { }
