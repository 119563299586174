import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormGroup, FormBuilder, Validators } from '@angular/forms';

import { DataService } from '../app/services/data.service';


// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";


import { getDatabase, ref, set } from "firebase/database";





@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'TrueCordisSignUp';
  protected aFormGroup: FormGroup;
  color: string;
  constructor(private formBuilder: FormBuilder, public dataService: DataService,
    ) {}

  showForm = true;
  captchaSuccess = false;
  form = new UntypedFormGroup ({

    cFirstName: new UntypedFormControl(''),
    cLastName: new UntypedFormControl(''),
    cBusName: new UntypedFormControl(''),
    cTitle: new UntypedFormControl(''),
    cEmail: new UntypedFormControl(''),
    cEmailVerify: new UntypedFormControl(''),
    
    cPhone: new UntypedFormControl(''),
    cSpecialty: new UntypedFormControl(null),

    
    cClientWebsite: new UntypedFormControl(''),

    
    // Password: new UntypedFormControl(''),
    // tStartDate: new FormControl(),
    

  });
  passwordBlur = false;
  userIDBlur = false;
  
  signupInfoText = 'Please fill out the following and press "Send me an Email to Signup". You will soon receive an email at the email address you just entered. \n Please click on the link to confirm your email address and continue to fill out additional information. \n We will be contacting you soon.  Thank you.'

  app:any;
  auth;
  uid;
  siteKey:any;
  token:any;
  userData:any;

  Changed = {
    cEmail:false,
    cEmailVerify:false,
    
    cPhone:false,
    cClientWebsite: false,
  }
  showMessage = false;
  errorMessage= false;
  message = 'Your request has been submitted. Our team will contact you soon regarding the next step in the process.'

  ngOnInit() {
    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['login', Validators.required]
    });
// Your web app's Firebase configuration
this.siteKey = "6LcN3FgnAAAAAL7Joz99_K28hAkJbPvhQMYPCtTs";

const firebaseConfig = {

  apiKey: "AIzaSyDYtVBNQx6ddJzKY4NszUXGhIOiTNVtGMM",

    authDomain: "tcsignup-4855d.firebaseapp.com",

      projectId: "tcsignup-4855d",

        storageBucket: "tcsignup-4855d.appspot.com",

          messagingSenderId: "1000169561597",

            appId: "1:1000169561597:web:0684d1828538d477a94d94",

            databaseURL: "https://tcsignup-4855d-default-rtdb.firebaseio.com",

            };



            // Initialize Firebase

            this.app = initializeApp(firebaseConfig);




            this.runSignIn()

  }
  formChanged(inputName) {
    this.Changed[inputName] = true

    // inputName === '' && 
    if(inputName === 'cEmailVerify' || inputName === 'cEmail') {

      if (this.form.value.cEmailVerify !== this.form.value.cEmail) {
        this.form.controls.cEmailVerify.setErrors({emailsDoNotMatch: true})
      } else {
        this.form.controls.cEmailVerify.setErrors(null)
      }
    }

  }

  onSubmit() {

    console.log(this.form )

    if (this.form.invalid) {
      console.log('form Invalid, onSubmit Canceled')
      return
    }



      // return
      if (this.form.value.cEmailVerify !== this.form.value.cEmail) {
        // this.color = 'is-danger';
        // this.message = 'Emails Do Not Match';
        // this.errorMessage = true;
        // Make the Message visible.
        // this.showMessage = true;
        return;
      }


    const formData = {

       
      cFirstName: this.form.value.cFirstName,
      cLastName: this.form.value.cLastName,
      cBusName: this.form.value.cBusName,
      cTitle: this.form.value.cTitle,
      cEmail: this.form.value.cEmail,
      cPhone: this.form.value.cPhone.replace(/\D/g,''),
      cSpecialty: this.form.value.cSpecialty,
      
      cClientWebsite: this.form.value.cClientWebsite
    }
      
      console.log('formData',formData)


   const httpRequest = this.dataService.dataAccessJson(this.token,formData);
    console.log('httpRequest',httpRequest)
    this.showMessage = true;
    this.form.disable();

    // console.log(this.form )

  }
  onCancel(){
    this.captchaSuccess = false;
    this.showForm = true;

  }

  httpTest() {
    const dummyData = {jsonAjax: {
      data: {firstName:'nothing'}
    }}
    this.dataService.dataAccessJson('test',dummyData);
  }

  handleLoad(){}
  handleExpire(){
    this.captchaSuccess = false;

  }
  handleReset(){}
  
  handleSuccess(event){
    console.log('SUCCESSFUL CAPTCHA LOGGGED')
    console.log(event)
    this.captchaSuccess = true;
    this.token = event;
    let dummyUserData = {
      "firstName":"Sam",
      "lastName":"Jones",
      "companyName":"ABC Corp.",
      "title":"President",
      "email":"sam.jones@abc.com",
      "phone":"123-456-7890",
      "companyWebsite":"abc.com"
    }
    //    this.dataService.dataAccessJson(event,dummyUserData);
    // this.tokenResponse = this.dataService.dataAccessJson(event);
    console.log('before tokenResponse')
    // console.log(this.tokenResponse)
    console.log('after tokenResponse')
  }



  writeUserData(userId, 
    cFirstName, 
    cLastName, 
    cBusName,
    cTitle,
    cEmail,
    cPhone,
    cClientWebsite,
    ) {

    const db = getDatabase();
  
    set(ref(db, 'users/' + userId), {
  
      cFirstName: cFirstName,
      cLastName: cLastName,
      cBusName: cBusName,
      cTitle: cTitle,
      cEmail: cEmail,
      cPhone: cPhone,
      cClientWebsite: cClientWebsite
       
  
    });
  
  }
  

  runSignIn() {

    // const auth = getAuth();

    this.auth = getAuth();

    signInAnonymously(this.auth)
    
      .then(() => {
    
          // Signed in..
          console.log('signInAnonymously ran without Error?')
    
            })
    
              .catch((error) => {
    
                  const errorCode = error.code;
    
                      const errorMessage = error.message;
    
                          // ...
    
                            });


            this.auth = getAuth();

            onAuthStateChanged(this.auth, (user) => {
            
              if (user) {
            
                  // User is signed in, see docs for a list of available properties
            
                      // https://firebase.google.com/docs/reference/js/auth.user
            
                          this.uid = user.uid;
                          console.log('user is signed in')
                          console.log('UID = ' + this.uid)
                              // ...
            
                                } else {
            
                                    // User is signed out
            
                                    console.log('user is signed out')
                                        // ...
            
                                          }
            
                                          });


  }



  toggleForm() {
    this.showForm = !this.showForm;
    console.log('showForm value:', this.showForm);

  }
  setblur(control, valid) {
    console.log('control', control);
    console.log('valid', valid);
    if (valid) {
      const controlBlur = control + 'Blur';
      this[controlBlur] = true;
    }
  }




  clear(name) {
    if (name == 'cPhone') {
      this.form.controls[name].setValue('');
      this.Changed.cPhone = false;
    } else {
      this.form.controls[name].setValue(null);
    }
  }


  
}
