import { Router } from '@angular/router';
import { Injectable, isDevMode } from '@angular/core';
//import { Injectable } from '@angular/core';
// import { Http } from '@angular/http';

import { HttpClient,HttpHeaders, } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';
//import 'rxjs/add/operator/map'; old
//import { map } from 'rxjs/operators';
//import {Headers} from '@angular/http';
// operators all come from `rxjs/operators`
/* not currently used
import { Observable, Subject, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
*/
// import { isDevMode } from '@angular/core';

@Injectable()
//providedIn: 'root'
export class DataService {
  oneWeeksTime = 604800000;
  cFullName:string;
  public cURL:string;
  apiJsonURL;
  public currentData:any = {
    "iRequestKey":null,
    "iWorkorderKey":null,
    "dStartDate":null,
    "cRegion":null,
    "cCounty":null,
    "cClientID":null,
    "cProductID":null,
    "cDepartment":'O',
    "RequestGroup":[]
  };
  pdfURL: string;
  pdfURLSafe:SafeResourceUrl;

  constructor(
    public http: HttpClient,
    private router: Router,   
    public sanitizer:DomSanitizer 
  ) {


    this.apiJsonURL = 'https://data.truecordis.co/api/process/tcmiscjson/initialSignup';
   

  }

  


  
  dataAccessJson(token,userData) {
     // "data":{}}}
    const InputObj: any = {
      "jsonAjax": {
        "token": token,
        "data":userData
      }
    } 

  

    let data: any = InputObj;
     console.log('login data = ',data);
    // let data: any = ljInput;
        //data = JSON.stringify(data);
    const myHeaders = new HttpHeaders()
      // .set("Access-Control-Request-Headers", "Content-Type")
      .set("Content-Type","application/json")
      // .set("timeout", "100000")


    console.log('data (NO stringify) ',data);      
    console.log(myHeaders);         
    console.log('cPostURL','/api')
    // to make the proxy thing work need to pass a /api so that 
    // https://autocourt.truecordis.co/api/aceandc01 will work

    // return 
    let newHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    
    // return 
    // this.http.post(this.apiJsonURL, data, {headers: newHeaders })
    // return this.http.post(this.apiJsonURL, data, {headers: newHeaders })
    return this.http.post(this.apiJsonURL, JSON.stringify(data), {headers: newHeaders }).toPromise();
    // .toPromise();
  }



    //}
  //}
 
  //Methods END

}




